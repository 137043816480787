import {
    PreloaderElasticEvent,
    PreloaderDispatchEvent,
    PreloaderPerformanceWatch,
    PreloaderClassSelector,
    PreloaderClass,
} from './models/enums/consts';
import Container from 'typedi';
import { LocalStorageToken, SessionStorageToken, WindowToken } from '../../../injection-tokens';

export abstract class PreloaderBase {
    protected _preloaderTimeout: any;
    protected _watchEvents: Array<any> = [];
    protected _retries: number = 0;
    protected readonly _options = {
        connectionTimeout: { slow: 30000, normal: 60000 },
    };

    protected readonly _window: Window;
    protected readonly _sessionStorage: Storage;
    protected readonly _localStorage: Storage;

    protected constructor() {
        this._window = Container.get(WindowToken);
        this._sessionStorage = Container.get(SessionStorageToken);
        this._localStorage = Container.get(LocalStorageToken);
        this.init();
    }

    public init(): void {
        if (!!preloaderHtmlContent) {
            const bodyObserver = new MutationObserver((mutations, observer) => {
                if (this._window.document?.body) {
                    observer.disconnect();

                    this.startPerformanceWatch(PreloaderPerformanceWatch.PreloaderInitial);

                    const preloaderHtmlContentDiv = this._window.document.createElement('div');
                    preloaderHtmlContentDiv.classList.add(PreloaderClass.MainLoader);

                    this._window.document?.body.classList.add(PreloaderClass.NoScroll);

                    preloaderHtmlContentDiv.innerHTML = preloaderHtmlContent.trim();
                    this._window.document?.body?.insertBefore(
                        preloaderHtmlContentDiv,
                        this._window.document.body.firstChild,
                    );

                    this._preloaderTimeout = this._initTimeout(
                        this._options.connectionTimeout.normal,
                    );
                }
            });
            bodyObserver.observe(this._window.document.documentElement, { childList: true });
        }
    }

    public setWatchEvents = (watchEvents: Array<any>): void => {
        const preloaderWatchEvents = watchEvents.map((x) => ({
            name: x,
            isFailed: null,
        }));
        this._watchEvents.push(...preloaderWatchEvents);
    };

    public onEventFinished = (event: string, eventResponseData: any): void => {
        if (!event || !this._preloaderTimeout || !eventResponseData) return;

        const watchEvent = this._watchEvents.find((x) => x.name == event);

        if (!!watchEvent) {
            watchEvent.isFailed = !eventResponseData.isLoaded;

            const cancelIt = this._shouldStop(true);
            if (cancelIt) {
                this.cancel();
                // TODO - maybe we should wait for this?
                this._window.PF?.Web?.ClientsFramework?.PokerNativeInterface?.onSiteLoaded();

                return;
            }

            const finishIt = this._shouldStop(false);
            if (finishIt) this.finish();
        }
    };

    public getDuration = () => {
        return this.getPerformanceDuration();
    };

    public isSlowConnection = (): boolean => {
        const slowConnectionEffectiveType = ['slow-2g', '2g', '3g']; //'4g'

        const windowNavigator: any = this._window.navigator;
        if (windowNavigator) {
            const connection =
                windowNavigator.connection ||
                windowNavigator.mozConnection ||
                windowNavigator.webkitConnection;

            if (connection)
                return slowConnectionEffectiveType.indexOf(connection.effectiveType) >= 0;
        }

        return false;
    };

    public cancel = (): void => {
        this.clearEvents();

        this.remove();

        const notifyElasticModel = {
            duration: this.getPerformanceDuration(),
            event: PreloaderElasticEvent.PreloaderHide,
        };

        this.notifyElastic(notifyElasticModel);

        this.clearInitialPerformanceWatch();

        this.dispatchEvent(false);
    };

    public finish = (notifyElastic: boolean = true): void => {
        this.clearEvents();
        if (notifyElastic) {
            const notifyElasticModel = {
                duration: this.getPerformanceDuration(),
                event: PreloaderElasticEvent.FirstHitErrorShown,
            };
            this.notifyElastic(notifyElasticModel);
        }
        this.clearInitialPerformanceWatch();
        this.dispatchEvent(true);
    };

    public remove = (): void => {
        this._remove(PreloaderClassSelector.MainLoader);

        this._window.document?.body.classList.remove(PreloaderClass.NoScroll);
    };

    protected getPerformanceDuration = (): number => {
        const preloaderInitialWatches = performance.getEntriesByName(
            PreloaderPerformanceWatch.PreloaderInitial,
        );
        if (preloaderInitialWatches.length == 0) return null;

        const measurePerformanceName = 'preloaderPerformance';
        performance.measure(measurePerformanceName, PreloaderPerformanceWatch.PreloaderInitial);

        const performanceMeasure = performance.getEntriesByName(measurePerformanceName).pop();

        if (!!performanceMeasure) {
            performance.clearMeasures(measurePerformanceName);

            return performanceMeasure.duration;
        }

        return null;
    };

    protected clearEvents(): void {
        if (!!this._preloaderTimeout) {
            clearTimeout(this._preloaderTimeout);
            this._preloaderTimeout = null;
        }
    }

    protected startPerformanceWatch(name): void {
        performance.mark(name);
    }

    protected clearPerformanceWatch(performanceWatch: string): void {
        performance.clearMarks(performanceWatch);
    }

    protected clearInitialPerformanceWatch(): void {
        performance.clearMarks(PreloaderPerformanceWatch.PreloaderInitial);
    }

    protected notifyElastic(elasticData): void {
        try {
            const serviceUrl = this._window.pageContextData.site.serviceUrl;
            const apiPreloaderUrl = '/api/Notification/Preloader/';
            const url = new URL(apiPreloaderUrl, serviceUrl || this._window.location.origin).href;

            elasticData.host = this._window.location.host;
            elasticData.path = decodeURI(this._window.location.pathname);
            elasticData.pageType = preloaderPageType;
            const jsonData = JSON.stringify(elasticData);

            const xmlhttp = new XMLHttpRequest();
            xmlhttp.onreadystatechange = function () {
                if (xmlhttp.readyState == XMLHttpRequest.DONE) {
                }
            };
            xmlhttp.open('POST', url, true);
            xmlhttp.setRequestHeader('Content-Type', 'application/json');

            const contextData = this._computeHeaderContextData();
            xmlhttp.setRequestHeader('ContextData', contextData);

            const overrideContextData = this._computeHeaderOverrideContextData();
            if (overrideContextData)
                xmlhttp.setRequestHeader('OverrideContextData', overrideContextData);

            const clientDeviceData = this._computeHeaderClientDeviceData();
            xmlhttp.setRequestHeader('ClientDeviceData', clientDeviceData);

            const correlationId = this.uuidv4();
            xmlhttp.setRequestHeader('X-Correlation-ID', correlationId);

            xmlhttp.send(jsonData);
        } catch (err) {
            console.error(err);
        }
    }

    protected dispatchEvent(isFinishedEvent: boolean, eventData: any = null): void {
        const customEvent = new CustomEvent(
            isFinishedEvent ? PreloaderDispatchEvent.Finish : PreloaderDispatchEvent.Cancel,
            eventData,
        );

        this._window.dispatchEvent(customEvent);
    }

    protected _remove = (containerId): void => {
        const container = <HTMLElement>document.querySelector(containerId);

        if (!!container) container.remove();
    };

    protected _initTimeout(timespan: number): any {
        return setTimeout(() => {
            const slowConnection = this.isSlowConnection();

            if (slowConnection && this._retries < 1) {
                this._preloaderTimeout = this._initTimeout(this._options.connectionTimeout.slow);
                this._retries = this._retries + 1;
            } else this.finish();
        }, timespan);
    }

    private _shouldStop(shouldCancel: boolean): boolean {
        if (this._watchEvents.length == 0) return false;

        const isPreloaderRunning = !!this._preloaderTimeout;
        if (isPreloaderRunning) {
            const handledEvents = this._watchEvents.filter(
                (x) => x.isFailed == !shouldCancel && x.isFailed != null,
            );

            return handledEvents.length == this._watchEvents.length;
        }

        return false;
    }

    private uuidv4(): string {
        return ('' + [1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (ch) => {
            let c = Number(ch);
            return (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(
                16,
            );
        });
    }

    private _computeHeaderClientDeviceData = (): string => {
        let clientDeviceData: any = {
            isiPad: this._findIfIsiPad(),
            isNative: this._findIfIsNative(),
            nativeUXVersion: this._getNativeUXVersion(),
        };

        if (clientDeviceData.isNative && this._sessionStorage.NativeDataStore) {
            const nativeDataStore = JSON.parse(this._sessionStorage.NativeDataStore);
            if (nativeDataStore) {
                clientDeviceData.nativeAppName = nativeDataStore.Bundle;
                clientDeviceData.nativeClientVersion = nativeDataStore.AppVersion;
                clientDeviceData.nativeOS = nativeDataStore.OS;
                clientDeviceData.nativeLanguage = nativeDataStore.Lang;
                clientDeviceData.nativeDeviceType = nativeDataStore.DeviceType;
                clientDeviceData.iosAppleId = nativeDataStore.AppID;
            }
        }

        return JSON.stringify(clientDeviceData);
    };

    private _findIfIsiPad = (): boolean => {
        return (
            this._window.navigator &&
            ((this._window.navigator.platform === 'MacIntel' &&
                this._window.navigator.maxTouchPoints > 0) ||
                this._window.navigator.platform === 'iPad')
        );
    };

    private _findIfIsNative = (): boolean => {
        const isAndroidNative =
            typeof this._window.WrapperInterface === 'object' &&
            typeof this._window.NativeInterface != 'undefined';

        if (isAndroidNative) return true;

        const isiOSNative =
            typeof this._window.NativeInterface != 'undefined' &&
            typeof this._window.webkit === 'object' &&
            typeof this._window.webkit.messageHandlers.callbackHandler === 'object';
        if (isiOSNative) return true;

        const nativeUXVersion = this._getNativeUXVersion();
        if (nativeUXVersion > 0) return true;

        return this._sessionStorage.NativeDataStore != undefined;
    };

    private _getNativeUXVersion = () => {
        const parameterName = 'nativeUXVersion';

        let version = this._getQueryStringParameterByName(parameterName);

        if (!version) version = this._localStorage.getItem(parameterName);

        return version;
    };

    private _getQueryStringParameterByName = (name): any => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        // search for the parameter value, case-insensitive
        const regex = new RegExp('[\\?&]' + name + '=([^&#]*)', 'i'),
            results = regex.exec(this._window.location.search);

        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    private _computeHeaderContextData = (): string => {
        let serializedContextData = null;

        if (this._window.contextData) {
            let contextData = JSON.parse(this._window.contextData);

            if (contextData.siteUrl) contextData.siteUrl = encodeURI(contextData.siteUrl);

            serializedContextData = JSON.stringify(contextData);
        }

        return serializedContextData;
    };

    private _computeHeaderOverrideContextData = () => {
        var overrideContextData = this._window.overrideContextData;

        return overrideContextData;
    };
}
