import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import { ILogger } from '@sparkware/uc-sdk-core';
import { PreloaderType } from './models/enums/consts';
import { StaticPreloader } from './preloader-static';
import { PreloaderMissing } from './preloader-missing';
import { WindowToken } from '../../../injection-tokens';

@Service()
export class PreloaderWrapper {
    private _preloader: StaticPreloader | PreloaderMissing;
    private readonly _window: Window;
    private readonly _logger: ILogger;

    public get preloader() {
        return this._preloader;
    }

    constructor() {
        this._window = Container.get(WindowToken);
        this._logger = Container.get(LoggerProvider).getLogger('PreloaderWrapper');
        this._injectStyles();
        this._setPreloader();
    }

    public init(preloaderWatchEvents: Array<string>) {
        if (preloaderWatchEvents.length == 0)
            this._logger.warn('preloader initialized with empty array of watch events.');
        else {
            this.preloader.setWatchEvents(preloaderWatchEvents);
        }
    }

    public onEventFinished(watchEvent: string, data: any): void {
        this.preloader.onEventFinished(watchEvent, data);
    }

    public getDuration(): number {
        return this.preloader.getDuration();
    }

    public cancel(): void {
        this.preloader.cancel();
    }

    public finish(notifyElastic: boolean): void {
        this.preloader.finish(notifyElastic);
    }

    public removeFailed(): void {
        this.preloader.removeErrorScreen();
    }

    public remove(): void {
        this.preloader.remove();
    }

    public showFailed(): void {
        this.preloader.showErrorScreen();
    }

    private _injectStyles(): void {
        const style = this._window.document.createElement('style');
        style.textContent = `
            .preloader-no-scroll {
                overflow: hidden !important;
            }
        `;
        this._window.document.head.appendChild(style);
        this._logger.debug('Injected preloader-no-scroll CSS styles.');
    }

    private _setPreloader = () => {
        const serverPreloaderType = preloaderType?.trim() || '';
        if (!serverPreloaderType) {
            this._logger.error('[Preloader] - Failed to load preloader settings');
        }

        switch (serverPreloaderType) {
            case PreloaderType.Static:
                this._preloader = Container.get(StaticPreloader);
                break;
            default:
                this._preloader = Container.get(PreloaderMissing);
        }
    };
}
