import { Service, Container } from 'typedi';
import { ILoggerConfiguration } from './interfaces/ILoggerConfiguration';
import { LocalStorageUtils } from '../../../Modules/Storage/utils';
import { WindowToken } from '../../injection-tokens';
import { LogLevelMap, MinLevelToLog } from './enums';
import { ITrackJSClientLogger } from './interfaces/ITrackJSClientLogger';

@Service()
export class TrackJSClientLogger implements ITrackJSClientLogger {
    private readonly _window: Window;
    private readonly consoleProxy: Console;

    constructor() {
        this._window = Container.get(WindowToken);
        let trackJSConsole: Console;
        if (this._window?.TrackJS?.console) {
            trackJSConsole = this._window?.TrackJS?.console;
        } else {
            trackJSConsole = console;
            console.warn(`fallback to default console, TrackJS was not found`);
        }
        this.consoleProxy = new Proxy(console, {
            get: (console, property: keyof Console) => {
                const { selectedLogLevel } = this._getMinLogLevel();
                const logLevel = LogLevelMap[property];
                const logger =
                    MinLevelToLog[logLevel] >= selectedLogLevel ? console : trackJSConsole;
                return (...args: any[]) => {
                    logger?.[property]?.apply(null, args || []);
                };
            },
        });
    }

    public getTrackJSLogger(): Console {
        return this.consoleProxy;
    }

    private _getMinLogLevel = (): ILoggerConfiguration => {
        const { defaultLogLevel = 'error' } = this._window.pageContextData.environment;
        const _defaultLogLevel = MinLevelToLog[LogLevelMap[defaultLogLevel]];
        return {
            selectedLogLevel: Number(LocalStorageUtils.get('UC.MINLOGLEVEL') || _defaultLogLevel),
        };
    };
}
