import {
    PreloaderClass,
    PreloaderClassSelector,
    PreloaderElasticEvent,
    PreloaderPerformanceWatch,
} from './models/enums/consts';
import { PreloaderFailed } from './preloader-failed';
import { Service } from 'typedi';

@Service()
export class StaticPreloader extends PreloaderFailed {
    constructor() {
        super();
    }

    public override init(): void {
        this.startPerformanceWatch(PreloaderPerformanceWatch.PreloaderInitial);
        this._preloaderTimeout = this._initTimeout(this._options.connectionTimeout.normal);
        this._window.document.body.classList.add(PreloaderClass.NoScroll);
    }

    public override remove = (): void => {
        this._remove(PreloaderClassSelector.StaticLoader);
        this._window.document.body.classList.remove(PreloaderClass.NoScroll);
        this._window.document.getElementById('uc-container').classList.remove('transparent-el');
    };

    public finish = (notifyElastic: boolean = true): void => {
        this.clearEvents();
        if (notifyElastic) {
            const notifyElasticModel = {
                duration: this.getPerformanceDuration(),
                event: PreloaderElasticEvent.FirstHitErrorShown,
            };
            this.notifyElastic(notifyElasticModel);
        }
        this.clearInitialPerformanceWatch();
        this.dispatchEvent(true);
    };

    public removeErrorScreen = (): void => {};

    public showErrorScreen = (): HTMLElement => {
        return null;
    };
}
