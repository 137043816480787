import { PreloaderFailed } from './preloader-failed';
import { Service } from 'typedi';

@Service()
export class PreloaderMissing extends PreloaderFailed {
    constructor() {
        super();
    }

    public init(): void {}

    public setWatchEvents = (watchEvents: Array<any>): void => {};

    public onEventFinished = (event: string, eventResponseData: any): void => {};

    public getDuration = () => {
        return null;
    };

    public cancel = (): void => {};

    public finish = (notifyElastic: boolean = true): void => {};

    public remove = (): void => {};

    public showErrorScreen = (): HTMLElement => {
        return null;
    };

    public removeErrorScreen = (): void => {};

    protected getPerformanceDuration = (): number => {
        return null;
    };

    protected clearEvents(): void {}

    protected startPerformanceWatch(name): void {}

    protected clearPerformanceWatch(performanceWatch: string): void {}

    protected clearInitialPerformanceWatch(): void {}

    protected notifyElastic(elasticData): void {}

    protected dispatchEvent(isFinishedEvent: boolean, eventData: any = null): void {}

    protected showError(classSelector): void {}

    protected setStyleDisplay(classSelector: string, display: string): void {}
}
