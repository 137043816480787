import { PreloaderBase } from './preloader-base';
import {
    PreloaderClassSelector,
    PreloaderFailedClassSelector,
    PreloaderElasticEvent,
} from './models/enums/consts';

export abstract class PreloaderFailed extends PreloaderBase {
    protected constructor() {
        super();
    }

    public finish = (notifyElastic: boolean = true): void => {
        this.clearEvents();
        this.showError(PreloaderClassSelector.MainLoader);
        if (notifyElastic) {
            const notifyElasticModel = {
                duration: this.getPerformanceDuration(),
                event: PreloaderElasticEvent.FirstHitErrorShown,
            };
            this.notifyElastic(notifyElasticModel);
        }
        this.clearInitialPerformanceWatch();
        this.dispatchEvent(true);
    };

    public showErrorScreen = (): HTMLElement => {
        const loaderContent = preloaderHtmlContent.trim();
        let errorContainer = this._window.document.querySelector(
            PreloaderFailedClassSelector.ErrorContainer,
        );
        if (!errorContainer) {
            errorContainer = this._window.document.createElement('div');
            errorContainer.classList.add(PreloaderFailedClassSelector.ErrorContainer.substring(1));
        }
        errorContainer.innerHTML = loaderContent;
        this._window.document.body.prepend(errorContainer);
        this.showError(PreloaderFailedClassSelector.ErrorContainer);
        return <HTMLElement>errorContainer;
    };

    public removeErrorScreen = (): void => {
        const container = <HTMLElement>(
            this._window.document.querySelector(PreloaderFailedClassSelector.ErrorContainer)
        );
        if (!!container) container.remove();
    };

    protected showError(classSelector): void {
        this.setStyleDisplay(classSelector, '');
        this.setStyleDisplay(PreloaderFailedClassSelector.ErrorText, '');
        this._window.document
            .querySelector(PreloaderFailedClassSelector.Retry)
            .addEventListener('click', this.reloadPage);
        this.setStyleDisplay(PreloaderFailedClassSelector.LoadingText, 'none');
    }

    protected setStyleDisplay(classSelector: string, display: string): void {
        (<HTMLElement>this._window.document.querySelector(classSelector)).style.display = display;
    }

    protected reloadPage(): void {
        this._window.location.reload();
    }
}
