export class LocalStorageUtils {
    static isLocalStorageSupported(): boolean {
        return window && typeof window.localStorage != 'undefined' && window.localStorage != null;
    }

    static set(key: string, item: string) {
        if (LocalStorageUtils.isLocalStorageSupported()) window.localStorage.setItem(key, item);
    }

    static get(key: string): string {
        return LocalStorageUtils.isLocalStorageSupported()
            ? window.localStorage.getItem(key)
            : null;
    }

    static remove(key: string) {
        if (LocalStorageUtils.isLocalStorageSupported()) window.localStorage.removeItem(key);
    }

    static clear() {
        if (LocalStorageUtils.isLocalStorageSupported()) window.localStorage.clear();
    }
}
